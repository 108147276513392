#aside .ant-menu-root {
  background: #f3f7fd;
}
#aside .flag-select {
  display: none;
}
@media only screen and (max-width: 499px) {
  #aside .flag-select {
    display: block;
  }
}
#aside .ant-menu-sub {
  background-color: #fcfcfc;
}
#aside .menuLink {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.9vw;
  line-height: 0.9vw;
  letter-spacing: 0.1vw;
  color: #505761;
  display: flex;
  align-items: center;
  height: 100%;
  user-select: none;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #aside .menuLink {
    font-size: 2.1vw;
    line-height: 2.1vw;
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 499px) {
  #aside .menuLink {
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 1px;
  }
}
#aside .menuLink:hover {
  transition: all 0.25s;
  color: #007aff;
}
#aside .menuLink:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: "";
}
#aside .ant-menu-item-selected {
  background-color: transparent;
  background-color: #e6f7ff;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
}
#aside .ant-menu-item-selected:after {
  opacity: 0;
}
#aside .ant-menu-item-selected > a {
  color: #007aff !important;
}
#aside .ant-menu-item-selected:after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#aside .ant-menu-item {
  padding: 0 !important;
  margin: 0;
}
#aside .ant-menu-item .asideLink {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.9vw;
  line-height: 0.9vw;
  letter-spacing: 0.1vw;
  color: #505761;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 5.2vw;
  user-select: none;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #aside .ant-menu-item .asideLink {
    font-size: 2.1vw;
    line-height: 2.1vw;
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 499px) {
  #aside .ant-menu-item .asideLink {
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 1px;
  }
}
#aside .ant-menu-item .asideLink:hover {
  transition: all 0.25s;
  color: #007aff;
}
#aside .ant-menu-item .asideLink:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: "";
}
#aside .ant-menu-item .active {
  background-color: #e6f7ff;
  color: #007aff;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
}
#aside .ant-menu-item .active:after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#aside .anticon svg {
  width: 1.3vw;
  height: 1.3vw;
}
#aside .ant-menu-item .anticon,
#aside .ant-menu-submenu-title .anticon {
  margin-right: 1vw;
}
#aside .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 2.9vw !important;
  height: 4vw;
  line-height: 4vw;
  user-select: none;
}
#aside .ant-menu-inline {
  border: none;
}
#aside .ant-menu-submenu-title .anticon + span {
  text-transform: uppercase;
  user-select: none;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
}
#aside .ant-menu-submenu-selected {
  color: #007aff;
}
#aside .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 4vw;
  line-height: 4vw;
}
#aside .menuItem {
  padding-left: 2.9vw !important;
  height: 4vw;
  line-height: 4vw;
  user-select: none;
}