.orders {
  width: 74.6vw;
  margin: 2vw 7.85vw 2vw 3.85vw;
  position: relative;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders {
    padding: 5.21vw 11.5vw 0 11.5vw;
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .orders {
    padding: 5.34vw 6.7vw 0 6.7vw;
    margin: 0;
    width: 100%;
  }
}
.orders__titleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__titleWrapper {
    margin-bottom: 4vw;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 499px) {
  .orders__titleWrapper {
    margin-bottom: 7vw;
    flex-wrap: wrap;
  }
}
.orders__title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__title {
    font-size: 2.14vw;
    line-height: 4.17vw;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .orders__title {
    font-size: 3.3vw;
    line-height: 6.4vw;
  }
}
.orders__row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__row {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 499px) {
  .orders__row {
    flex-wrap: wrap;
  }
}
.orders__wrapperInput {
  width: 23%;
  position: relative;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapperInput {
    width: 45%;
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapperInput {
    margin-bottom: 7vw;
    width: 100%;
  }
}
.orders__wrapperInput_invalid {
  color: #ff455c;
  bottom: -1.3vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.8vw;
  width: fit-content;
  position: absolute;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapperInput_invalid {
    font-size: 1.31vw;
    line-height: 1.31vw;
    bottom: -15px;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapperInput_invalid {
    font-size: 2.67vw;
    line-height: 2.67vw;
    bottom: -15px;
  }
}
.orders__wrapperInput_disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.7;
  margin-right: 2vw;
}
.orders__wrapperInput_disabled_left {
  user-select: none;
  pointer-events: none;
  opacity: 0.7;
}
.orders__confirmBtn, .orders__updateBtn, .orders__stopBtn {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  min-width: fit-content;
  height: 3.12vw;
  width: 23%;
  min-width: 12.7vw;
  height: 3.91vw;
  margin-right: auto;
  margin-left: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__confirmBtn, .orders__updateBtn, .orders__stopBtn {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__confirmBtn, .orders__updateBtn, .orders__stopBtn {
    width: 23%;
    height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__confirmBtn, .orders__updateBtn, .orders__stopBtn {
    width: 100%;
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-bottom: 0;
    margin: 8vw 0;
  }
}
.orders__confirmBtn {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .orders__confirmBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .orders__confirmBtn {
    border: 1px solid white;
  }
}
.orders__confirmBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.orders__confirmBtn:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .orders__confirmBtn:hover {
    border: 1px solid #14d999;
  }
}
.orders__updateBtn {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #007aff;
  margin-right: auto;
  margin-left: 1.8vw;
}
@media only screen and (max-width: 1024px) {
  .orders__updateBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .orders__updateBtn {
    border: 1px solid white;
  }
}
.orders__updateBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.orders__updateBtn:hover {
  border: 1px solid #007aff;
  color: #007aff;
}
@media only screen and (max-width: 1024px) {
  .orders__updateBtn:hover {
    border: 1px solid #007aff;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__updateBtn {
    width: 45%;
    margin-bottom: 4vw;
    margin-left: auto;
    margin-right: 0;
  }
}
@media only screen and (max-width: 499px) {
  .orders__updateBtn {
    margin-left: 0;
  }
}
.orders__stopBtn {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .orders__stopBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .orders__stopBtn {
    border: 1px solid white;
  }
}
.orders__stopBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.orders__stopBtn:hover {
  border: 1px solid #ff455c;
  color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .orders__stopBtn:hover {
    border: 1px solid #ff455c;
  }
}
.orders__wrapper_title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  margin-bottom: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_title {
    font-size: 1.31vw;
    line-height: 1.31vw;
    margin-bottom: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_title {
    font-size: 3.3vw;
    line-height: 2.67vw;
    margin-bottom: 2.67vw;
  }
}
.orders__wrapper_input {
  height: 3.91vw;
  line-height: 3.91vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw;
  margin-top: 0.7vw;
  width: 100%;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_input {
    width: 100%;
    height: 7.82vw;
    line-height: 7.82vw;
    margin-top: 1.31vw;
    font-size: 2.1vw;
    padding: 1.6vw;
    border: 1px solid #f2f3f9;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_input {
    width: 100%;
    max-width: 100%;
    border: 1px solid #f2f3f9;
    height: 16vw;
    line-height: 16vw;
    margin-top: 2.67vw;
    font-size: 4.3vw;
    padding: 3.2vw;
  }
}
.orders__wrapper_input:hover, .orders__wrapper_input:active, .orders__wrapper_input:focus {
  border: 1px solid #007aff;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_input:hover, .orders__wrapper_input:active, .orders__wrapper_input:focus {
    border: 1px solid #007aff;
  }
}
.orders__wrapper_input::placeholder {
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #92969c;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_input::placeholder {
    font-size: 2.1vw;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_input::placeholder {
    border-radius: 2px;
    font-size: 4.3vw;
  }
}
.orders__wrapper_label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_label {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_label {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}

.line {
  height: 1px;
  background-color: #e8e8e8;
  margin: 4vw 0;
}
@media only screen and (max-width: 499px) {
  .line {
    margin: 10vw 0;
  }
}

.noData {
  text-align: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.5vw;
  line-height: 1.5vw;
  color: #151719;
  margin-top: 4vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .noData {
    font-size: 2.14vw;
    line-height: 4.17vw;
  }
}
@media only screen and (max-width: 499px) {
  .noData {
    font-size: 3.3vw;
    line-height: 6.4vw;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 18vw;
  padding-left: 5.5vw;
  background: #fcfcfc;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .spinner {
    padding-left: 0;
  }
}