.pairSubstitution.ant-checkbox-wrapper {
  margin-bottom: 0;
  padding-top: 0;
  margin-right: initial;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .pairSubstitution.ant-checkbox-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 499px) {
  .pairSubstitution.ant-checkbox-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}

#root .trackSellToBuyOrders {
  margin: 0;
  padding: 0;
  margin-left: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #root .trackSellToBuyOrders {
    margin-left: 0;
    margin-right: 3vw;
    width: fit-content;
  }
  #root .trackSellToBuyOrders span {
    font-size: 2vw;
  }
}
@media only screen and (max-width: 499px) {
  #root .trackSellToBuyOrders {
    margin-left: 0;
    width: fit-content;
    margin-top: 4vw;
  }
  #root .trackSellToBuyOrders span {
    font-size: 3vw;
  }
}