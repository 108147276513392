.animationLogo,
.animationSelectLang,
.animationButtons div {
  transform: translateY(-100px);
  transition: transform 0.5s;
}
@media only screen and (max-width: 1024px) {
  .animationLogo,
  .animationSelectLang,
  .animationButtons div {
    transform: translateY(0);
  }
}

.domLoaded .animationLogo,
.domLoaded .animationSelectLang {
  transform: translateY(0);
}

.animationLogo {
  transition-delay: 0.05s;
}

.animationSelectLang {
  transition-delay: 0.15s;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 499px) {
  .animationSelectLang {
    display: none;
  }
}

#root .mobileMenu__selectLanguage .flag-select .flag-option {
  margin: 0;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #root .mobileMenu__selectLanguage .flag-select .flag-option {
    height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  #root .mobileMenu__selectLanguage .flag-select .flag-option {
    height: 16vw;
  }
}
#root .mobileMenu__selectLanguage .flag-select.change-language-flags .flag-options {
  right: 0;
  overflow: auto;
  display: flex;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #root .mobileMenu__selectLanguage .flag-select.change-language-flags .flag-options {
    top: 7vw;
    right: 5vw;
  }
}
@media only screen and (max-width: 499px) {
  #root .mobileMenu__selectLanguage .flag-select.change-language-flags .flag-options {
    top: 12.5vw;
    right: 0;
  }
}
#root .mobileMenu__selectLanguage .flag-select img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  object-fit: cover;
  top: 0;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #root .mobileMenu__selectLanguage .flag-select img {
    width: 4.7vw;
    height: 4.7vw;
  }
}
@media only screen and (max-width: 499px) {
  #root .mobileMenu__selectLanguage .flag-select img {
    width: 8.7vw;
    height: 8.7vw;
  }
}
#root .mobileMenu__selectLanguage .flag-select .country-flag .country-label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  color: #151719;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #root .mobileMenu__selectLanguage .flag-select .country-flag .country-label {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 0 5.21vw 0 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  #root .mobileMenu__selectLanguage .flag-select .country-flag .country-label {
    font-size: 3.2vw;
    line-height: 3.2vw;
    padding: 0 0 0 4.3vw;
  }
}
#root .mobileMenu__selectLanguage .flag-select.change-language-flags .arrow-down {
  margin: 0;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  #root .mobileMenu__selectLanguage .flag-select.change-language-flags .arrow-down {
    font-size: 3.14vw;
  }
}
@media only screen and (max-width: 499px) {
  #root .mobileMenu__selectLanguage .flag-select.change-language-flags .arrow-down {
    font-size: 6.4vw;
  }
}

@media only screen and (max-width: 499px) {
  .ant-drawer #aside {
    display: flex;
  }
  .ant-drawer #aside .ant-menu-submenu-title .anticon + span {
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 0.3vw;
  }
  .ant-drawer #aside .anticon svg {
    width: 5vw;
    height: 5vw;
  }
  .ant-drawer #aside .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 12vw;
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 1px;
    color: #505761;
    font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
  }
  .ant-drawer #aside .ant-menu-submenu-title .anticon,
  .ant-drawer #aside .ant-menu-item .anticon {
    margin-right: 3vw;
  }
  .ant-drawer #aside .menuItem {
    height: 12vw;
  }
  .ant-drawer #aside .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 12vw;
  }
  .ant-drawer #aside .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-drawer #aside .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
    background: #505761;
  }
}

@media only screen and (max-width: 499px) {
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 2.67vw;
    margin-bottom: 2.67vw;
  }
}

@media only screen and (max-width: 499px) {
  .ant-menu-submenu-title span {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 499px) {
  .ant-drawer-body {
    padding: 5vh 0 25vw 0 !important;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 499px) {
  .flag-select__option .flag-select__option__icon {
    top: 0;
  }
  .flag-select__option {
    display: inline-flex;
  }
  .flag-select__btn {
    padding: 0 4px;
  }
  .ant-drawer-content-wrapper {
    width: 85vw !important;
  }
}