.createNewBot {
  width: 74.6vw;
  margin: 2vw 7.85vw 2vw 3.85vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot {
    padding: 5.21vw 11.5vw 0 11.5vw;
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot {
    padding: 5.34vw 6.7vw 0 6.7vw;
    margin: 0;
    width: 100%;
  }
}
.createNewBot__titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__titleWrapper {
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__titleWrapper {
    margin-bottom: 7vw;
  }
}
.createNewBot__title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__title {
    font-size: 2.14vw;
    line-height: 4.17vw;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__title {
    font-size: 3.3vw;
    line-height: 6.4vw;
  }
}
.createNewBot__wrapperInput {
  margin-bottom: 2vw;
  width: 15vw;
  position: relative;
}
.createNewBot__wrapperInput:not(:nth-child(4n)) {
  margin-right: 2.75vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapperInput:not(:nth-child(4n)) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapperInput:not(:nth-child(4n)) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapperInput {
    width: 23vw;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapperInput {
    margin-bottom: 7vw;
    width: 100%;
  }
}
.createNewBot__wrapperInput_invalid {
  color: #ff455c;
  bottom: -1.3vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.8vw;
  width: fit-content;
  position: absolute;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapperInput_invalid {
    font-size: 1.31vw;
    line-height: 1.31vw;
    bottom: -15px;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapperInput_invalid {
    font-size: 2.67vw;
    line-height: 2.67vw;
    bottom: -15px;
  }
}
.createNewBot__wrapperInputLabel {
  display: flex;
  align-items: center;
}
.createNewBot__wrapperInputLabel label {
  margin-bottom: 0;
}
.createNewBot__wrapper_leftSide {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 0;
  justify-content: space-between;
}
.createNewBot__wrapper_leftSide > div {
  max-width: 14.789vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapper_leftSide {
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 0;
    justify-content: space-between;
  }
  .createNewBot__wrapper_leftSide > div {
    max-width: 24.889vw;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapper_leftSide {
    flex-wrap: wrap;
  }
  .createNewBot__wrapper_leftSide > div {
    max-width: 100%;
  }
}
.createNewBot__wrapper_leftSide:not(:last-child) {
  margin-bottom: 3.3vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapper_leftSide:not(:last-child) {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapper_leftSide:not(:last-child) {
    margin-bottom: 8vw;
  }
}
.createNewBot__wrapper_rightSide {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapper_rightSide {
    margin: 4vw 0 0 0;
  }
}
.createNewBot__wrapper_title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  margin-bottom: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapper_title {
    font-size: 1.31vw;
    line-height: 1.31vw;
    margin-bottom: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapper_title {
    font-size: 3.3vw;
    line-height: 2.67vw;
    margin-bottom: 2.67vw;
  }
}
.createNewBot__wrapper_input {
  height: 3.91vw;
  line-height: 3.91vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw;
  margin-top: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapper_input {
    width: 100%;
    height: 7.82vw;
    line-height: 7.82vw;
    margin-top: 1.31vw;
    font-size: 2.1vw;
    padding: 1.6vw;
    border: 1px solid #f2f3f9;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapper_input {
    width: 100%;
    max-width: 100%;
    border: 1px solid #f2f3f9;
    height: 16vw;
    line-height: 16vw;
    margin-top: 2.67vw;
    font-size: 4.3vw;
    padding: 3.2vw;
  }
}
.createNewBot__wrapper_input:hover, .createNewBot__wrapper_input:active, .createNewBot__wrapper_input:focus {
  border: 1px solid #007aff;
}
@media only screen and (max-width: 1024px) {
  .createNewBot__wrapper_input:hover, .createNewBot__wrapper_input:active, .createNewBot__wrapper_input:focus {
    border: 1px solid #007aff;
  }
}
.createNewBot__wrapper_input::placeholder {
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #92969c;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapper_input::placeholder {
    font-size: 2.1vw;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapper_input::placeholder {
    border-radius: 2px;
    font-size: 4.3vw;
  }
}
.createNewBot__wrapper_label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__wrapper_label {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__wrapper_label {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}
.createNewBot__btnSubmit {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.15vw;
  width: fit-content;
  min-width: 15vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__btnSubmit {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .createNewBot__btnSubmit {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__btnSubmit {
    border: 1px solid white;
  }
}
.createNewBot__btnSubmit:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.createNewBot__btnSubmit:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .createNewBot__btnSubmit:hover {
    border: 1px solid #14d999;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .createNewBot__btnSubmit {
    margin: 3.91vw 0;
    width: 31.3vw;
    height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .createNewBot__btnSubmit {
    width: 100%;
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-bottom: 0;
    margin: 8vw 0;
  }
}
.createNewBot__btnSubmit {
  margin-top: 1vw;
}

.greenRedButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .greenRedButtonWrapper {
    margin-top: 2.61vw;
  }
}