.highcharts {
    &-background {
        fill: transparent !important;
    }

    &-container {
        margin-bottom: 3vw;
    }

    &-scrollbar,
    &-credits,
    &-navigator-outline {
        display: none;
    }
}
