.avatar {
  position: relative;
  display: flex;
}
.avatar__status, .avatar__speed {
  position: absolute;
  left: -0.2vw;
  width: 1vw;
  height: 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .avatar__status, .avatar__speed {
    width: 1.8vw;
    height: 1.8vw;
  }
}
@media only screen and (max-width: 499px) {
  .avatar__status, .avatar__speed {
    width: 3.8vw;
    height: 3.8vw;
  }
}
.avatar__status {
  top: -0.2vw;
}
@media only screen and (max-width: 499px) {
  .avatar__status {
    left: -1vw;
  }
}
.avatar__speed {
  bottom: -0.2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .avatar__speed {
    bottom: 1vw;
  }
}
@media only screen and (max-width: 499px) {
  .avatar__speed {
    bottom: -1vw;
    left: -1vw;
  }
}