.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.35vw 7.85vw;
  position: relative;
  z-index: 9999;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .header {
    padding: 2.35vw 4.4vw 2.35vw 11.5vw;
  }
}
@media only screen and (max-width: 499px) {
  .header {
    padding: 8vw 6.7vw;
    background-color: #fafbff;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #007aff;
  font-size: 1vw;
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .header__logo {
    font-size: 2.1vw;
  }
}
@media only screen and (max-width: 499px) {
  .header__logo {
    font-size: 3.2vw;
  }
}
.header__logo img {
  width: 4.5vw;
  height: 3.5vw;
  margin-right: 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .header__logo img {
    width: 9vw;
    height: 7vw;
  }
}
@media only screen and (max-width: 499px) {
  .header__logo img {
    width: 12vw;
    height: 10vw;
  }
}
.header__burgerMenu {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header__burgerMenu {
    display: block;
  }
}

.headerCabinet {
  padding: 1.35vw 7.85vw 1.35vw 2vw;
  border-bottom: 1px solid #e8e8e8;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .headerCabinet {
    padding: 2.35vw 4.4vw 2.35vw 10.5vw;
  }
}
@media only screen and (max-width: 499px) {
  .headerCabinet {
    padding: 8vw 6.7vw;
  }
}