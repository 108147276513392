@import '../../assets/styles/variables';

.avatar {
    position: relative;
    display: flex;

    &__status,
    &__speed {
        position: absolute;
        left: -0.2vw;
        width: 1vw;
        height: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: $tablet14px;
            height: $tablet14px;
        }

        @media only screen and (max-width: 499px) {
            width: 3.8vw;
            height: 3.8vw;
        }
    }

    &__status {
        top: -0.2vw;

        @media only screen and (max-width: 499px) {
            left: -1vw;
        }
    }

    &__speed {
        bottom: -0.2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            bottom: 1vw;
        }

        @media only screen and (max-width: 499px) {
            bottom: -1vw;
            left: -1vw;
        }
    }
}
