@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.35vw 7.85vw;
    position: relative;
    z-index: 9999;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet18px 4.4vw $tablet18px $tablet88px;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px $mobile25px;
        background-color: $bodyColor;
    }

    &__logo {
        display: flex;
        align-items: center;
        font-family: $MontserratRegular;
        color: $blueDark;
        font-size: 1vw;
        text-transform: uppercase;
        font-weight: 700;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
        }

        img {
            width: 4.5vw;
            height: 3.5vw;
            margin-right: 1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 9vw;
                height: 7vw;
            }

            @media only screen and (max-width: 499px) {
                width: 12vw;
                height: 10vw;
            }
        }
    }

    &__burgerMenu {
        display: none;

        @media only screen and (max-width: 767px) {
            display: block;
        }
    }
}

.headerCabinet {
    padding: 1.35vw 7.85vw 1.35vw 2vw;
    border-bottom: 1px solid #e8e8e8;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: 2.35vw 4.4vw 2.35vw 10.5vw;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile30px $mobile25px;
    }
}
