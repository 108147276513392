@import '../../../assets/styles/variables';

.personalAreaWrapper {
    display: flex;
    min-height: calc(100vh - 6.3vw);
    background-color: $cabinerBackColor;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        min-height: calc(100vh - 20.3vw);
    }

    @media only screen and (max-width: 499px) {
        min-height: calc(100vh - 27vw);
    }
}
