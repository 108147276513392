.personalAreaWrapper {
  display: flex;
  min-height: calc(100vh - 6.3vw);
  background-color: #fcfcfc;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .personalAreaWrapper {
    min-height: calc(100vh - 20.3vw);
  }
}
@media only screen and (max-width: 499px) {
  .personalAreaWrapper {
    min-height: calc(100vh - 27vw);
  }
}