.aside {
  display: flex;
  flex-direction: column;
  width: 20vw;
  min-width: 20vw;
  min-height: calc(100vh - 10.85vw);
  background: #f3f7fd;
  border-right: 1px solid #e8e8e8;
}
@media only screen and (max-width: 1024px) {
  .aside {
    display: none;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: transparent;
  }
}
.aside__nav {
  display: flex;
  align-items: center;
  margin: 4px 0 4px 2.9vw;
  height: 4vw;
  line-height: 4vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .aside__nav {
    margin: 0 0 0 4.7vw;
  }
}
@media only screen and (max-width: 499px) {
  .aside__nav {
    margin: 0 6.7vw 0 2.9vw;
    height: 12vw;
  }
}
.aside__nav li {
  width: 100%;
  height: 100%;
  display: flex;
}
.aside__nav li svg {
  margin-right: 1vw;
  width: 1.3vw;
  height: 1.3vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .aside__nav li svg {
    margin-right: 3.14vw;
    width: 2.61vw;
    height: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .aside__nav li svg {
    margin-right: 3vw;
    width: 4.3vw;
    height: 4.3vw;
  }
}
.aside__nav li a {
  display: flex;
  align-items: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  color: #505761;
  user-select: none;
  width: 100%;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .aside__nav li a {
    font-size: 2.1vw;
    line-height: 2.1vw;
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 499px) {
  .aside__nav li a {
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 0.3vw;
  }
}
.aside__nav li a:hover {
  transition: all 0.25s;
  color: #007aff;
}