.botsWrapper {
  width: 74.6vw;
  margin: 1.8vw 7.85vw 0 3.85vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper {
    padding: 5.21vw 11.5vw 0 11.5vw;
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper {
    padding: 5.34vw 6.7vw 0 6.7vw;
    margin: 0;
    width: 100%;
  }
}
.botsWrapper__bot {
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__bot {
    margin-bottom: 5.21vw;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__bot {
    margin-bottom: 8vw;
  }
}
.botsWrapper__bot_title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  margin-bottom: 1.9vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__bot_title {
    font-size: 2.14vw;
    line-height: 4.17vw;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__bot_title {
    font-size: 3.3vw;
    line-height: 6.4vw;
    margin-bottom: 7vw;
  }
}

.buttonsWrapper {
  display: flex;
}
@media only screen and (max-width: 499px) {
  .buttonsWrapper {
    display: block;
    flex-wrap: wrap;
  }
}

.startAllBotsBtn {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.91vw;
  display: flex;
  justify-content: center;
  width: 15vw;
  margin-left: 2.75vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .startAllBotsBtn {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .startAllBotsBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .startAllBotsBtn {
    border: 1px solid white;
  }
}
.startAllBotsBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.startAllBotsBtn:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .startAllBotsBtn:hover {
    border: 1px solid #14d999;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .startAllBotsBtn {
    height: 7.82vw;
    margin: 2.61vw 0 2vw 7vw;
    flex: 1;
  }
}
@media only screen and (max-width: 499px) {
  .startAllBotsBtn {
    height: 16vw;
    width: calc(50% - 4vw);
    font-size: 2.67vw;
    margin: 0 4vw 5.34vw 0;
  }
}

.greenRedButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .greenRedButtonWrapper {
    margin-top: 2.61vw;
  }
}