.statisticsWrapper {
  width: 74.6vw;
  margin: 1.8vw 7.85vw 0 3.85vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .statisticsWrapper {
    padding: 5.21vw 11.5vw 0 11.5vw;
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .statisticsWrapper {
    padding: 5.34vw 6.7vw 0 6.7vw;
    margin: 0;
    width: 100%;
  }
}
.statisticsWrapper__bot_title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  margin-bottom: 4vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .statisticsWrapper__bot_title {
    font-size: 2.14vw;
    line-height: 4.17vw;
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 499px) {
  .statisticsWrapper__bot_title {
    font-size: 3.3vw;
    line-height: 6.4vw;
    margin-bottom: 7vw;
  }
}
.statisticsWrapper__noData {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 1vw;
  line-height: 2vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  width: 50%;
  text-align: center;
  margin: 0 auto;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .statisticsWrapper__noData {
    font-size: 2vw;
    line-height: 4vw;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .statisticsWrapper__noData {
    font-size: 4vw;
    line-height: 6vw;
    width: 100%;
  }
}