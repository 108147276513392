.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}
.inputWrapper__invalid {
  color: #ff455c;
  bottom: -1.3vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.8vw;
  width: 100%;
  position: absolute;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .inputWrapper__invalid {
    font-size: 1.31vw;
    line-height: 1.31vw;
    bottom: -15px;
  }
}
@media only screen and (max-width: 499px) {
  .inputWrapper__invalid {
    font-size: 2.67vw;
    line-height: 2.67vw;
    bottom: -15px;
  }
}