@media only screen and (max-width: 1024px) {
  .login form {
    width: 100%;
  }
}
.login__title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.55vw;
  color: #151719;
  margin-bottom: 2vw;
  text-align: center;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__title {
    font-size: 3.14vw;
    margin-bottom: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__title {
    font-size: 6.4vw;
    margin-bottom: 5.34vw;
  }
}
.login__close {
  position: absolute;
  top: 1.7vw;
  right: 1.7vw;
  cursor: pointer;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__close {
    top: 3.39vw;
    right: 3.39vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__close {
    top: 6.7vw;
    right: 6.7vw;
  }
}
.login__label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  display: flex;
  flex-direction: column;
  font-size: 0.8vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__label {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__label {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}
.login__inputWrapper {
  margin-bottom: 2vw;
  position: relative;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__inputWrapper {
    margin-bottom: 3.91vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__inputWrapper {
    margin-bottom: 8vw;
  }
}
.login__inputWrapper_eye {
  position: absolute;
  right: 1vw;
  top: 2.7vw;
  cursor: pointer;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__inputWrapper_eye {
    top: 50%;
    right: 1.31vw;
    width: 3.91vw;
    height: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__inputWrapper_eye {
    top: 50%;
    right: 4vw;
    width: 8vw;
    height: 5.34vw;
  }
}
@media only screen and (max-width: 1024px) {
  .login__inputWrapper_eye img {
    width: 100%;
  }
}
.login__input {
  width: 26vw;
  height: 3.9vw;
  line-height: 3.9vw;
  margin-top: 0.5vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  border: none;
  padding: 0 4vw 0 1vw;
  font-size: 1vw;
  outline: none;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__input {
    font-size: 2.1vw;
    line-height: 7.82vw;
    padding-left: 1.31vw;
    margin-top: 1.31vw;
    width: 100%;
    height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__input {
    font-size: 4.3vw;
    line-height: 16vw;
    padding-left: 2.67vw;
    margin-top: 2.67vw;
    width: 100%;
    height: 16vw;
  }
}
.login__input::placeholder {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1vw;
  line-height: 3.9vw;
  color: #92969c;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__input::placeholder {
    font-size: 2.1vw;
    line-height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__input::placeholder {
    font-size: 4.3vw;
    line-height: 16vw;
  }
}
.login__button {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #007aff;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.9vw;
  width: fit-content;
  min-width: 11.7vw;
  margin-top: 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__button {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .login__button {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .login__button {
    border: 1px solid white;
  }
}
.login__button:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.login__button:hover {
  border: 1px solid #007aff;
  color: #007aff;
}
@media only screen and (max-width: 1024px) {
  .login__button:hover {
    border: 1px solid #007aff;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__button {
    height: 7.82vw;
    font-size: 1.6vw;
    line-height: 1.6vw;
    margin-top: 5.21vw;
    letter-spacing: 1px;
    width: 50%;
  }
}
@media only screen and (max-width: 499px) {
  .login__button {
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-top: 5.34vw;
    letter-spacing: 1px;
    width: 100%;
  }
}
.login__buttonWrapper {
  display: flex;
  justify-content: center;
}
.login__twoFactorAuth_title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #4a4a4a;
  font-size: 0.8vw;
  line-height: 1.3vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__twoFactorAuth_title {
    font-size: 2.1vw;
    line-height: 3.14vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_title {
    font-size: 4.3vw;
    line-height: 6.4vw;
  }
}
.login__twoFactorAuth_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__twoFactorAuth_wrapper {
    margin-top: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_wrapper {
    margin-top: 5.34vw;
  }
}
.login__twoFactorAuth_label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  display: flex;
  flex-direction: column;
  font-size: 0.8vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__twoFactorAuth_label {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_label {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}
.login__twoFactorAuth_inputWrapper {
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .login__twoFactorAuth_inputWrapper {
    width: 45%;
  }
}
.login__twoFactorAuth_inputWrapper_eye {
  position: absolute;
  right: 1vw;
  top: 2.7vw;
  cursor: pointer;
}
.login__twoFactorAuth_input {
  width: 11.7vw;
  height: 3.9vw;
  margin-top: 0.5vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  border: none;
  padding: 0 1vw;
  font-size: 1vw;
  line-height: 3.9vw;
  outline: none;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__twoFactorAuth_input {
    font-size: 2.1vw;
    line-height: 7.82vw;
    padding-left: 1.31vw;
    margin-top: 1.31vw;
    width: 100%;
    height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_input {
    font-size: 4.3vw;
    line-height: 16vw;
    padding-left: 2.67vw;
    margin-top: 2.67vw;
    width: 100%;
    height: 16vw;
  }
}
.login__twoFactorAuth_input::placeholder {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1vw;
  line-height: 3.9vw;
  color: #92969c;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__twoFactorAuth_input::placeholder {
    font-size: 2.1vw;
    line-height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_input::placeholder {
    font-size: 4.3vw;
    line-height: 16vw;
  }
}
.login__twoFactorAuth_submit {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: transparent;
  color: #007aff;
  border: 1px solid #007aff;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.9vw;
  width: fit-content;
  min-width: 11.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__twoFactorAuth_submit {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .login__twoFactorAuth_submit {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_submit {
    border: 1px solid white;
  }
}
.login__twoFactorAuth_submit:hover {
  background-color: transparent;
  transition: all 0.25s;
}
@media only screen and (max-width: 1024px) {
  .login__twoFactorAuth_submit {
    border: 1px solid #007aff;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_submit {
    background-color: #007aff;
    color: #ffffff;
  }
}
.login__twoFactorAuth_submit:hover {
  background-color: #007aff;
  color: #ffffff;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .login__twoFactorAuth_submit {
    height: 7.82vw;
    font-size: 1.6vw;
    line-height: 1.6vw;
    letter-spacing: 1px;
    width: 45%;
  }
}
@media only screen and (max-width: 499px) {
  .login__twoFactorAuth_submit {
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    letter-spacing: 1px;
    width: 45%;
  }
}