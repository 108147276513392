@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixin';

.greenRedButtonBlockWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: $tablet20px;
    }

    &__createNew,
    &__stopAll {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 3.91vw;
        display: flex;
        justify-content: center;
        width: 15vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            width: 21vw;
        }

        @media only screen and (max-width: 499px) {
            height: $mobile60px;
            width: calc(50% - 4vw);
            font-size: $mobile10px;
        }
    }

    &__createNew {
        @include greenBtnWithBorderAndBackground;
        margin-right: 2.75vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0 4vw $tablet20px 0;
        }

        @media only screen and (max-width: 499px) {
            margin: 0 4vw $mobile20px 0;
        }
    }

    &__stopAll {
        @include redBtnWithBorderAndBackground;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0 0 $tablet20px 4vw;
        }

        @media only screen and (max-width: 499px) {
            margin: 0 0 $mobile20px 4vw;
        }
    }
}
