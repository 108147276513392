@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.pairSubstitution.ant-checkbox-wrapper {
    margin-bottom: 0;
    padding-top: 0;
    margin-right: initial;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        display: flex;
        justify-content: flex-end;
    }

    @media only screen and (max-width: 499px) {
        display: flex;
        justify-content: flex-end;
    }
}

#root .trackSellToBuyOrders {
    margin: 0;
    padding: 0;
    margin-left: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-left: 0;
        margin-right: 3vw;
        width: fit-content;

        span {
            font-size: 2vw;
        }
    }

    @media only screen and (max-width: 499px) {
        margin-left: 0;
        width: fit-content;
        margin-top: 4vw;

        span {
            font-size: 3vw;
        }
    }
}
