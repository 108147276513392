.firstScreen {
  display: flex;
  justify-content: space-between;
  padding: 0 7.85vw;
  height: calc(100vh - 10vw);
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .firstScreen {
    flex-direction: column-reverse;
    min-height: auto;
    height: auto;
    padding: 5.4vw 6.7vw;
  }
}
@media only screen and (max-width: 499px) {
  .firstScreen {
    flex-direction: column-reverse;
    min-height: auto;
    height: auto;
    padding: 5.4vw 6.7vw;
  }
}
.firstScreen__leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .firstScreen__leftSide {
    margin: 5.21vw 11vw 0 11vw;
  }
}
@media only screen and (max-width: 499px) {
  .firstScreen__leftSide {
    margin-top: 15vw;
  }
}
.firstScreen__rightSide {
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 1024px) {
  .firstScreen__rightSide {
    transform: translate(0, 0);
  }
}
.firstScreen__rightSide_illustration {
  width: 50vw;
  height: 35vw;
  transition: 0.5s;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .firstScreen__rightSide_illustration {
    width: 100%;
    height: 54.46vw;
  }
}
@media only screen and (max-width: 499px) {
  .firstScreen__rightSide_illustration {
    width: 100%;
    height: 58vw;
  }
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.spin i {
  background-color: #007aff;
}
.spin div {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #007aff;
  font-size: 1vw;
}

@keyframes bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}