.dailyVolume__titleWrapper {
  display: flex;
  margin-bottom: 2vw;
  width: 100%;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .dailyVolume__titleWrapper {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 499px) {
  .dailyVolume__titleWrapper {
    flex-wrap: wrap;
  }
}
.dailyVolume__title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  width: 15vw;
  margin-right: 2.75vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .dailyVolume__title {
    font-size: 2.14vw;
    line-height: 4.17vw;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .dailyVolume__title {
    font-size: 3.3vw;
    line-height: 6.4vw;
    margin-bottom: 2vw;
    width: 100%;
  }
}
.dailyVolume__nowDailyVolumeIs {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  width: 15vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .dailyVolume__nowDailyVolumeIs {
    font-size: 2.14vw;
    line-height: 4.17vw;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .dailyVolume__nowDailyVolumeIs {
    font-size: 3.3vw;
    line-height: 6.4vw;
    margin-bottom: 2vw;
    width: 100%;
  }
}
.dailyVolume__nowDailyVolumeIs_red {
  color: #ff455c;
}
.dailyVolume__btnrefreshDailyPrediction {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.91vw;
  width: fit-content;
  min-width: 15vw;
  margin-right: auto;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .dailyVolume__btnrefreshDailyPrediction {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .dailyVolume__btnrefreshDailyPrediction {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .dailyVolume__btnrefreshDailyPrediction {
    border: 1px solid white;
  }
}
.dailyVolume__btnrefreshDailyPrediction:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.dailyVolume__btnrefreshDailyPrediction:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .dailyVolume__btnrefreshDailyPrediction:hover {
    border: 1px solid #14d999;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .dailyVolume__btnrefreshDailyPrediction {
    margin: 3.91vw 0;
    width: 31.3vw;
    height: 7.82vw;
    width: 50vw;
  }
}
@media only screen and (max-width: 499px) {
  .dailyVolume__btnrefreshDailyPrediction {
    width: 100%;
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-bottom: 0;
    margin-bottom: 7vw;
  }
}
.dailyVolume__btnrefreshDailyPrediction_spin {
  margin-left: 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .dailyVolume__btnrefreshDailyPrediction_spin {
    margin-left: 2vw;
  }
}
@media only screen and (max-width: 499px) {
  .dailyVolume__btnrefreshDailyPrediction_spin {
    margin-left: 4vw;
  }
}

.inputWrapper {
  margin-bottom: 2vw;
  width: 15vw;
  position: relative;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .inputWrapper {
    width: 23vw;
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 499px) {
  .inputWrapper {
    margin-bottom: 7vw;
    width: 100%;
  }
}
.inputWrapper_invalid {
  color: #ff455c;
  bottom: -1.3vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.8vw;
  width: fit-content;
  position: absolute;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .inputWrapper_invalid {
    font-size: 1.31vw;
    line-height: 1.31vw;
    bottom: -15px;
  }
}
@media only screen and (max-width: 499px) {
  .inputWrapper_invalid {
    font-size: 2.67vw;
    line-height: 2.67vw;
    bottom: -15px;
  }
}
.inputWrapper_input {
  height: 3.91vw;
  line-height: 3.91vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw;
  margin-top: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .inputWrapper_input {
    width: 100%;
    height: 7.82vw;
    line-height: 7.82vw;
    margin-top: 1.31vw;
    font-size: 2.1vw;
    padding: 1.6vw;
    border: 1px solid #f2f3f9;
  }
}
@media only screen and (max-width: 499px) {
  .inputWrapper_input {
    width: 100%;
    max-width: 100%;
    border: 1px solid #f2f3f9;
    height: 16vw;
    line-height: 16vw;
    margin-top: 2.67vw;
    font-size: 4.3vw;
    padding: 3.2vw;
  }
}
.inputWrapper_input:hover, .inputWrapper_input:active, .inputWrapper_input:focus {
  border: 1px solid #007aff;
}
@media only screen and (max-width: 1024px) {
  .inputWrapper_input:hover, .inputWrapper_input:active, .inputWrapper_input:focus {
    border: 1px solid #007aff;
  }
}
.inputWrapper_input::placeholder {
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #92969c;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .inputWrapper_input::placeholder {
    font-size: 2.1vw;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 499px) {
  .inputWrapper_input::placeholder {
    border-radius: 2px;
    font-size: 4.3vw;
  }
}
.inputWrapper_label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .inputWrapper_label {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .inputWrapper_label {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}