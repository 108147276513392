@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.firstScreen {
    display: flex;
    justify-content: space-between;
    padding: 0 7.85vw;
    height: calc(100vh - 10vw);

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        flex-direction: column-reverse;
        min-height: auto;
        height: auto;
        padding: 5.4vw 6.7vw;
    }

    @media only screen and (max-width: 499px) {
        flex-direction: column-reverse;
        min-height: auto;
        height: auto;
        padding: 5.4vw 6.7vw;
    }

    &__leftSide {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: $tablet40px $mobile42px 0 $mobile42px;
        }

        @media only screen and (max-width: 499px) {
            margin-top: 15vw;
        }
    }

    &__rightSide {
        align-items: center;
        display: flex;

        @media only screen and (max-width: 1024px) {
            transform: translate(0, 0);
        }

        &_illustration {
            width: 50vw;
            height: 35vw;
            transition: 0.5s;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: 54.46vw;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                height: 58vw;
            }
        }
    }
}

.spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    i {
        background-color: #007aff;
    }

    div {
        font-family: $MontserratRegular;
        color: #007aff;
        font-size: 1vw;
    }
}

@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}
