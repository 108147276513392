.pageNotFound {
  display: flex;
  align-items: center;
  justify-items: center;
  height: calc(100vh - 25vw);
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .pageNotFound {
    height: 100vh;
    position: absolute;
    top: -10vh;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 499px) {
  .pageNotFound {
    height: 75vh;
  }
}
.pageNotFound img {
  margin: 0 auto;
  width: 11vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .pageNotFound img {
    width: 75%;
  }
}
@media only screen and (max-width: 499px) {
  .pageNotFound img {
    width: 50vw;
  }
}