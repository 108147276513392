@import '../../../../assets/styles/variables';

.aside {
    display: flex;
    flex-direction: column;
    width: 20vw;
    min-width: 20vw;
    min-height: calc(100vh - 10.85vw);
    background: #f3f7fd;
    border-right: 1px solid #e8e8e8;

    @media only screen and (max-width: 1024px) {
        display: none;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: transparent;
    }

    &__nav {
        display: flex;
        align-items: center;
        margin: 4px 0 4px 2.9vw;
        height: 4vw;
        line-height: 4vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: 0 0 0 $tablet36px;
        }

        @media only screen and (max-width: 499px) {
            margin: 0 6.7vw 0 2.9vw;
            height: 12vw;
        }

        li {
            width: 100%;
            height: 100%;
            display: flex;

            svg {
                margin-right: 1vw;
                width: 1.3vw;
                height: 1.3vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-right: $tablet24px;
                    width: $tablet20px;
                    height: $tablet20px;
                }

                @media only screen and (max-width: 499px) {
                    margin-right: 3vw;
                    width: $mobile16px;
                    height: $mobile16px;
                }
            }

            a {
                display: flex;
                align-items: center;
                font-family: $MontserratRegular;
                font-size: 0.8vw;
                line-height: 0.8vw;
                letter-spacing: 0.1vw;
                text-transform: uppercase;
                color: $greyLight;
                user-select: none;
                width: 100%;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    line-height: $tablet16px;
                    letter-spacing: 1px;
                }

                @media only screen and (max-width: 499px) {
                    font-size: 3vw;
                    line-height: 3vw;
                    letter-spacing: 0.3vw;
                }

                &:hover {
                    transition: all 0.25s;
                    color: $blueDark;
                }
            }
        }
    }
}
