.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.errorWrapper img {
  max-width: 20vw;
  margin-bottom: 3vw;
}
.errorWrapper h2 {
  color: #505761;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 4vw;
  font-weight: 500;
  margin-bottom: 2vw;
  white-space: nowrap;
}

.relative {
  position: relative;
  min-height: 30vw;
  width: -webkit-fill-available;
}