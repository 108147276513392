/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
}

body {
  background-color: #fafbff;
}

#root {
  display: flex;
  flex-direction: column;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:disabled {
  opacity: 50%;
}

::-webkit-scrollbar {
  width: 0.7vw;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #555;
}

::-webkit-scrollbar-thumb:hover {
  background: #222;
}

.disabledField {
  opacity: 0.7;
  pointer-events: none;
  user-select: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

.ant-message {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #505761;
  font-size: 1vw;
  z-index: 9999999;
}

.antDSelect {
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  width: 100%;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect {
    font-size: 2.1vw;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect {
    font-size: 4.3vw;
    border-radius: 2px;
  }
}
.antDSelect > * {
  box-shadow: none !important;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
}
.antDSelect__wrapper {
  margin: 2.6vw 0;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1024px) {
  .antDSelect__wrapper {
    flex-wrap: wrap;
  }
}
.antDSelect__wrapper_deleteIcon {
  position: absolute;
  top: 2.9vw;
  right: 1vw;
  height: 0.8vw;
  width: 0.8vw;
  z-index: 9999;
  cursor: pointer;
  z-index: 1;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__wrapper_deleteIcon {
    right: 1.6vw;
    height: 1.6vw;
    width: 1.6vw;
    top: 5.7vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper_deleteIcon {
    right: 3.2vw;
    height: 2.6vw;
    width: 2.6vw;
    top: 12.4vw;
  }
}
.antDSelect__wrapper_item {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .antDSelect__wrapper_item {
    width: 28%;
    margin-bottom: 5.21vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper_item {
    width: 45%;
    margin-bottom: 11vw;
  }
}
.antDSelect__wrapper_item .select-search-box__search,
.antDSelect__wrapper_item .select-search-box__select {
  max-width: 12.7vw;
}
@media only screen and (max-width: 1024px) {
  .antDSelect__wrapper_item .select-search-box__search,
  .antDSelect__wrapper_item .select-search-box__select {
    max-width: 100%;
  }
}
.antDSelect__wrapper_item .select-search-box__search {
  padding-right: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__wrapper_item .select-search-box__search {
    padding-right: 4vw !important;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper_item .select-search-box__search {
    padding-right: 6vw !important;
  }
}
.antDSelect__wrapper_dash {
  color: #151719;
  height: 3.91vw;
  font-size: 1.3vw;
  margin-top: auto;
  display: flex;
  align-items: center;
  margin: auto 1vw 0 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__wrapper_dash {
    height: 7.82vw;
    margin: auto 2.35vw 0 2.35vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper_dash {
    font-size: 3vw;
    height: 16vw;
    margin: auto 3vw 0 3vw;
  }
}
.antDSelect__wrapper_input {
  width: 7vw;
  height: 3.91vw;
  line-height: 3.91vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw;
  margin-top: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__wrapper_input {
    font-size: 2.1vw;
    line-height: 7.82vw;
    padding-left: 1.6vw;
    margin-top: 1.31vw;
    width: 100%;
    height: 7.82vw;
    border: 1px solid #f2f3f9;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper_input {
    font-size: 4.3vw;
    line-height: 16vw;
    padding-left: 3.2vw;
    margin-top: 2.67vw;
    width: 100%;
    height: 16vw;
    border: 1px solid #f2f3f9;
  }
}
.antDSelect__wrapper_input:hover, .antDSelect__wrapper_input:active, .antDSelect__wrapper_input:focus {
  border: 1px solid #007aff;
}
@media only screen and (max-width: 1024px) {
  .antDSelect__wrapper_input:hover, .antDSelect__wrapper_input:active, .antDSelect__wrapper_input:focus {
    border: 1px solid #007aff;
    border-radius: 1px;
  }
}
.antDSelect__wrapper_input::placeholder {
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #92969c;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__wrapper_input::placeholder {
    font-size: 2.1vw;
    line-height: 3.39vw;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper_input::placeholder {
    font-size: 4.3vw;
    line-height: 6.7vw;
    border-radius: 2px;
  }
}
.antDSelect__wrapper_label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__wrapper_label {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper_label {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}
.antDSelect__wrapper-input {
  display: flex;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__wrapper-input {
    order: 1;
    justify-content: space-between;
    width: 64%;
    margin-bottom: 5.21vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__wrapper-input {
    order: 1;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 11vw;
  }
}
.antDSelect__title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  margin-bottom: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect__title {
    font-size: 1.31vw;
    line-height: 1.31vw;
    margin-bottom: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect__title {
    font-size: 2.67vw;
    line-height: 2.67vw;
    margin-bottom: 2.67vw;
  }
}
.antDSelect .ant-select-selection {
  min-width: 12.7vw;
  height: 3.91vw;
  border: 1px solid #f2f3f9;
  background-color: transparent;
  border-radius: 0.1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect .ant-select-selection {
    height: 7.82vw;
    border: 1px solid #f2f3f9;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect .ant-select-selection {
    height: 16vw;
    border: 1px solid #f2f3f9;
  }
}
.antDSelect .ant-select-selection:hover, .antDSelect .ant-select-selection:active, .antDSelect .ant-select-selection:focus {
  border: 1px solid #007aff;
}
@media only screen and (max-width: 1024px) {
  .antDSelect .ant-select-selection:hover, .antDSelect .ant-select-selection:active, .antDSelect .ant-select-selection:focus {
    border: 1px solid #007aff;
    border-radius: 2px;
  }
}
.antDSelect .anticon {
  color: #151719;
}
.antDSelect .ant-select-arrow {
  right: 1vw;
  font-size: 0.8vw;
  margin-top: 0;
  transform: translateY(-50%);
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect .ant-select-arrow {
    font-size: 1.6vw;
    right: 1.6vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect .ant-select-arrow {
    right: 3.2vw;
    font-size: 2.67vw;
  }
}
.antDSelect .ant-select-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
  outline: none;
  margin: 0 1vw;
  line-height: inherit;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect .ant-select-selection__rendered {
    margin: 0 1.6vw;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect .ant-select-selection__rendered {
    margin: 0 6vw 0 3.2vw;
  }
}
.antDSelect .ant-select-selection__placeholder {
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #92969c;
  transform: translateY(-50%);
  margin: 0;
  line-height: normal;
  height: auto;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .antDSelect .ant-select-selection__placeholder {
    font-size: 2.1vw;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 499px) {
  .antDSelect .ant-select-selection__placeholder {
    font-size: 4.3vw;
    border-radius: 2px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ant-message-notice:first-child {
    margin-top: 6.1vw;
  }
}
@media only screen and (max-width: 499px) {
  .ant-message-notice:first-child {
    margin-top: 16vw;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ant-message-notice-content {
    padding: 3.39vw 6.52vw;
  }
}
@media only screen and (max-width: 499px) {
  .ant-message-notice-content {
    padding: 6.7vw 14.7vw;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ant-message-custom-content span {
    font-size: 2.1vw;
  }
}
@media only screen and (max-width: 499px) {
  .ant-message-custom-content span {
    font-size: 4.3vw;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ant-message-custom-content i {
    font-size: 2.61vw;
    margin-right: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .ant-message-custom-content i {
    font-size: 5.34vw;
    margin-right: 2.67vw;
  }
}

.ant-radio-wrapper {
  display: flex;
  align-items: center;
}
.ant-radio-wrapper > * {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
}
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #1890ff;
}

span.ant-radio + * {
  padding: 0 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  span.ant-radio + * {
    font-size: 2.1vw;
    line-height: 2.1vw;
    padding: 0 3.14vw;
  }
}
@media only screen and (max-width: 499px) {
  span.ant-radio + * {
    font-size: 4.3vw;
    line-height: 4.3vw;
    padding: 0 6.4vw;
  }
}

.ant-switch {
  background-color: #92969c;
}

.ant-switch-checked {
  background-color: #14d999 !important;
}

.ant-checkbox-wrapper {
  margin-bottom: 2vw;
  width: 15vw;
  display: flex;
  align-items: center;
  padding-top: 1.5vw;
  margin-right: auto;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ant-checkbox-wrapper {
    width: 23vw;
    height: 7.82vw;
    margin-right: initial;
  }
}
@media only screen and (max-width: 499px) {
  .ant-checkbox-wrapper {
    width: 100%;
    margin-bottom: 7vw;
    margin-right: initial;
  }
}

.ant-checkbox + span {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ant-checkbox + span {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .ant-checkbox + span {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}

.ant-collapse {
  background: #f3f7fd;
}

.ant-collapse,
.ant-collapse-content {
  border-color: #e8e8e8;
}

.ant-collapse-header {
  display: flex;
  align-items: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  color: #505761;
  user-select: none;
  width: 100%;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ant-collapse-header {
    font-size: 2.1vw;
    line-height: 2.1vw;
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 499px) {
  .ant-collapse-header {
    font-size: 4.3vw;
    line-height: 4.3vw;
    letter-spacing: 1px;
  }
}

.ant-collapse-item-active .ant-collapse-header {
  background-color: #e6f7ff;
  color: #007aff !important;
}