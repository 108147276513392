.active svg path {
  fill: #007aff;
}

.profile__logoWrapper .headerUserAvatar {
  width: 5.3vw;
  height: 5.3vw;
  font-size: 1.5vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .profile__logoWrapper .headerUserAvatar {
    width: 9.8vw;
    height: 9.8vw;
    font-size: 2.1vw;
  }
}
@media only screen and (max-width: 499px) {
  .profile__logoWrapper .headerUserAvatar {
    width: 20.1vw;
    height: 20.1vw;
    font-size: 4.3vw;
  }
}

.ant-back-top {
  bottom: 5vw;
  right: 3vw;
  transition: all 300ms;
}
.ant-back-top:hover {
  transform: scale(1.2);
}
@media only screen and (max-width: 499px) {
  .ant-back-top {
    display: none;
  }
}

.ant-back-top-inner img {
  width: 50px;
  height: 50px;
}