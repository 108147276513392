.areYouSureModal {
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 4vw;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .areYouSureModal {
    width: 75vw;
    height: 35vw;
    padding: 10vw;
  }
}
@media only screen and (max-width: 499px) {
  .areYouSureModal {
    width: 75vw;
    padding: 11vw;
  }
}

.areYouSuremModalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10001;
}