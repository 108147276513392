aside .container {
  display: none;
}
@media only screen and (max-width: 499px) {
  aside .container {
    display: flex;
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
@media only screen and (max-width: 499px) {
  .container {
    position: absolute;
    left: 5.34vw;
    bottom: 11vw;
    flex-direction: row;
  }
}
.container:hover .container__lang {
  color: #fcfcfc;
}
.container__flagIcon {
  width: 1.53vw;
  height: 1.53vw;
  border: 0.072vw solid rgba(241, 241, 241, 0.4);
  border-radius: 50%;
  box-sizing: content-box;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .container__flagIcon {
    width: 2.61vw;
    height: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .container__flagIcon {
    width: 5.834vw;
    height: 5.834vw;
  }
}
.container__lang {
  font-family: "Montserrat700", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  bottom: -1.5vw;
  user-select: none;
  transition: all 0.2s;
  display: none;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .container__lang {
    font-size: 1.31vw;
    padding-left: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .container__lang {
    display: block;
    font-size: 2.67vw;
    letter-spacing: 0.28vw;
    margin-left: 0.28vw;
    color: #000000;
    padding-left: 5.34vw;
  }
}