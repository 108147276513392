.greenRedButtonBlockWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .greenRedButtonBlockWrapper {
    margin-top: 2.61vw;
  }
}
.greenRedButtonBlockWrapper__createNew, .greenRedButtonBlockWrapper__stopAll {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.91vw;
  display: flex;
  justify-content: center;
  width: 15vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__createNew, .greenRedButtonBlockWrapper__stopAll {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__createNew, .greenRedButtonBlockWrapper__stopAll {
    height: 7.82vw;
    width: 21vw;
  }
}
@media only screen and (max-width: 499px) {
  .greenRedButtonBlockWrapper__createNew, .greenRedButtonBlockWrapper__stopAll {
    height: 16vw;
    width: calc(50% - 4vw);
    font-size: 2.67vw;
  }
}
.greenRedButtonBlockWrapper__createNew {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
  margin-right: 2.75vw;
}
@media only screen and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__createNew {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .greenRedButtonBlockWrapper__createNew {
    border: 1px solid white;
  }
}
.greenRedButtonBlockWrapper__createNew:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.greenRedButtonBlockWrapper__createNew:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__createNew:hover {
    border: 1px solid #14d999;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__createNew {
    margin: 0 4vw 2.61vw 0;
  }
}
@media only screen and (max-width: 499px) {
  .greenRedButtonBlockWrapper__createNew {
    margin: 0 4vw 5.34vw 0;
  }
}
.greenRedButtonBlockWrapper__stopAll {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__stopAll {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .greenRedButtonBlockWrapper__stopAll {
    border: 1px solid white;
  }
}
.greenRedButtonBlockWrapper__stopAll:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.greenRedButtonBlockWrapper__stopAll:hover {
  border: 1px solid #ff455c;
  color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__stopAll:hover {
    border: 1px solid #ff455c;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .greenRedButtonBlockWrapper__stopAll {
    margin: 0 0 2.61vw 4vw;
  }
}
@media only screen and (max-width: 499px) {
  .greenRedButtonBlockWrapper__stopAll {
    margin: 0 0 5.34vw 4vw;
  }
}