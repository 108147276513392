.deleteModal__title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.7vw;
  line-height: 2vw;
  margin-bottom: 2vw;
  color: #1f1f1f;
  text-align: center;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .deleteModal__title {
    font-size: 3.14vw;
    margin-bottom: 5.21vw;
  }
}
@media only screen and (max-width: 499px) {
  .deleteModal__title {
    font-size: 4.3vw;
    margin-bottom: 8vw;
  }
}
.deleteModal__close {
  position: absolute;
  top: 1.7vw;
  right: 1.7vw;
  cursor: pointer;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .deleteModal__close {
    top: 3.7vw;
    right: 3.7vw;
  }
}
@media only screen and (max-width: 499px) {
  .deleteModal__close {
    top: 3.7vw;
    right: 3.7vw;
  }
}
.deleteModal__buttonWrapper {
  display: flex;
  justify-content: center;
}
.deleteModal__no, .deleteModal__yes {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 2.4vw;
  width: fit-content;
  min-width: 7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .deleteModal__no, .deleteModal__yes {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .deleteModal__no, .deleteModal__yes {
    height: 7.82vw;
    width: 100%;
    font-size: 2.1vw;
    min-width: 21vw;
  }
}
@media only screen and (max-width: 499px) {
  .deleteModal__no, .deleteModal__yes {
    height: 7.82vw;
    width: 100%;
    font-size: 3.2vw;
    min-width: 21vw;
  }
}
.deleteModal__no {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
  margin-right: 1vw;
}
@media only screen and (max-width: 1024px) {
  .deleteModal__no {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .deleteModal__no {
    border: 1px solid white;
  }
}
.deleteModal__no:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.deleteModal__no:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .deleteModal__no:hover {
    border: 1px solid #14d999;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .deleteModal__no {
    margin-right: 5vw;
  }
}
@media only screen and (max-width: 499px) {
  .deleteModal__no {
    margin-right: 5vw;
  }
}
.deleteModal__yes {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #ff455c;
  margin-left: 1vw;
}
@media only screen and (max-width: 1024px) {
  .deleteModal__yes {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .deleteModal__yes {
    border: 1px solid white;
  }
}
.deleteModal__yes:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.deleteModal__yes:hover {
  border: 1px solid #ff455c;
  color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .deleteModal__yes:hover {
    border: 1px solid #ff455c;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .deleteModal__yes {
    margin-left: 5vw;
  }
}
@media only screen and (max-width: 499px) {
  .deleteModal__yes {
    margin-left: 5vw;
  }
}