@import '../../assets/styles/variables';

.animationLogo,
.animationSelectLang,
.animationButtons div {
    transform: translateY(-100px);
    transition: transform 0.5s;

    @media only screen and (max-width: 1024px) {
        transform: translateY(0);
    }
}

.domLoaded .animationLogo,
.domLoaded .animationSelectLang {
    transform: translateY(0);
}

.animationLogo {
    transition-delay: 0.05s;
}

.animationSelectLang {
    transition-delay: 0.15s;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 499px) {
        display: none;
    }
}

#root .mobileMenu {
    &__selectLanguage {
        .flag-select .flag-option {
            margin: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                height: $tablet60px;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile60px;
            }
        }

        .flag-select.change-language-flags .flag-options {
            right: 0;
            overflow: auto;
            display: flex;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                top: 7vw;
                right: 5vw;
            }

            @media only screen and (max-width: 499px) {
                top: 12.5vw;
                right: 0;
            }
        }

        .flag-select img {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            object-fit: cover;
            top: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: $tablet36px;
                height: $tablet36px;
            }

            @media only screen and (max-width: 499px) {
                width: $mobile34px;
                height: $mobile34px;
            }
        }

        .flag-select .country-flag .country-label {
            font-family: $MontserratRegular;
            letter-spacing: 1px;
            color: $textDarkLight;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet12px;
                line-height: $tablet12px;
                padding: 0 $tablet40px 0 $tablet20px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile12px;
                padding: 0 0 0 $mobile16px;
            }
        }

        .flag-select.change-language-flags .arrow-down {
            margin: 0;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet24px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile24px;
            }
        }
    }
}

.ant-drawer #aside {
    @media only screen and (max-width: 499px) {
        display: flex;

        .ant-menu-submenu-title .anticon + span {
            font-size: 3vw;
            line-height: 3vw;
            letter-spacing: 0.3vw;
        }

        .anticon svg {
            width: 5vw;
            height: 5vw;
        }

        .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
            height: 12vw;
            font-size: 3vw;
            line-height: 3vw;
            letter-spacing: 1px;
            color: #505761;
            font-family: $MontserratRegular;
            display: flex;
            align-items: center;
        }

        .ant-menu-submenu-title .anticon,
        .ant-menu-item .anticon {
            margin-right: 3vw;
        }

        .menuItem {
            height: 12vw;
        }

        .ant-menu-sub.ant-menu-inline > .ant-menu-item {
            height: 12vw;
        }

        .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
            background: #505761;
        }
    }
}

.ant-menu-inline .ant-menu-submenu-title {
    @media only screen and (max-width: 499px) {
        margin-top: $mobile10px;
        margin-bottom: $mobile10px;
    }
}

.ant-menu-submenu-title span {
    @media only screen and (max-width: 499px) {
        display: flex;
        align-items: center;
    }
}

.ant-drawer-body {
    @media only screen and (max-width: 499px) {
        padding: 5vh 0 25vw 0 !important;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 499px) {
    .flag-select__option .flag-select__option__icon {
        top: 0;
    }

    .flag-select__option {
        display: inline-flex;
    }

    .flag-select__btn {
        padding: 0 4px;
    }

    .ant-drawer-content-wrapper {
        width: 85vw !important;
    }
}
